.news-content{
    text-align: left;
    align-items: flex-start;
    width: 100%;
}
.news-stack{
    background-color: #282828;
    transition: all .2s ease !important;
    border-radius: 8px;
    align-items: stretch;
    margin-bottom: 1.5rem;
    outline: 2px solid rgba(255, 0, 0, 0);
    outline-offset: 2px;
    cursor: pointer;
    overflow: hidden;
}
.news-stack:hover{
    background-color: #2d2d2d;
    outline-color: #888;
}
.news-stack img{
    transition: transform .2s ease;
}
.news-stack:hover img{
    transform: scale(1.05);
}

.news-details-wrapper {
    max-width: 800px;
    text-align: left;
    margin: 0 auto;
}

.news-details-wrapper img {
    max-width: 100%;
    border-radius: 12px;
}

.news-details {
    color: #ddd;
}

.news-details p {
    line-height: 1.7;
}

.news-details strong {
    color: #fff;
}

.news-details>h1, .news-details>h2, .news-details>h3, .news-details>h4 {
    color: #fff;
    font-weight: 500;
}


.news-details ul, .news-details ol {
    margin-bottom: 1.5rem;
}

.news-details ul>li, .news-details ol>li {
    margin-top: .5rem;
    margin-bottom: .5rem;
}

.news-details img {
    margin: 1rem 0;
    max-width: 100%;
}

.news-details>figure {
    margin: 0;
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
}

.news-details>figure>iframe {
    border: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.news-excerpt {
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
}